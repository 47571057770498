<template>
  <ul class="causes-list program-cause-list" v-if="program_type">
    <li >
      {{program_type.data[0].name}}
    </li>
  </ul>
</template>

<script>
  import api from "../../services/api";
  import useSWRV from "swrv";

  export default {
    name: "ProgramsType",
    props: ['id'],
    setup(props) {
      const {
        data: program_type,
      } = useSWRV(() => `items/program_type?status=published&filter[id]=${props.id}`, api.fetcher);

      return {
        program_type,
      };
    },
  }
</script>

<style scoped>
  .single-causes .causes-list li:last-child {
     padding-left: 0;
  }
  .single-causes .causes-list.program-cause-list {
    border: none;
   
  }
</style>
