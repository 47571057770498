<template>
  <div id="programs">
    <div class="page-title-area bg-6 page-title-area-padding" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
      <div class="container">
        <div class="page-title-content">
          <h2>Programs</h2>
        </div>
      </div>
    </div>

    <div class="bg-image">
      <section class="causes-section pt-3 pb-70" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
        <div class="loading" v-if="programs_data === undefined && !programs_error">
          <Loading/>
        </div>
        <div class="container">
          <div class="description mt-4">
             <p>
              PeaceJam has 6 unique curriculum that use the stories and work of our 14 Nobel Peace Prize winners to teach youth ages 5-25 about global issue areas, and how they can make a difference. Our programs enhance the academic and social-emotional skills of participating students, fostering the essential skills necessary to create positive change in the world.
            </p>
            <p>
              Each of PeaceJam's award-winning programs includes activities that enhance the academic, civic, and social-emotional skills of youth, fostering the essential leadership skills and character traits necessary to create positive change in the world and addressing issues such as bullying, racism & hate, poverty, and empathy.
            </p>
            <p>
              Each Curricula is aligned to educational standards and engages students in a high quality service-learning process aligned with PeaceJam's One Billion Acts of Peace - designed to build critical leadership skills, anti-bullying, global citizenship, and community engagement.
            </p>
          </div>
        </div>
        <div class="container" v-if="programs_data">

          <div class="virtual-programming-area mb-4">
            <div class="d-flex justify-content-end">
              <button class="learn-more" @click="goProgramming">
                <span class="circle" aria-hidden="true">
                  <span class="icon arrow"></span>
                </span>
                <span class="button-text">Virtual Programming</span>
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-6" v-for="program in programs_data.data" :key="program.id">
              <ProgramsCard :program="program" />
            </div>
          </div>
        </div>
      </section>

      <section class="causes-section pb-100" ref="programming">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="page-text">
                <h3 class="mb-3">Join Virtual Programming</h3>
                <p>
                  PeaceJam offices around the world are running programming direct to youth online! If you are interested in joining a virtual program,
                  please email <a href="mailto:programs@peacejam.org">programs@peacejam.org</a>. For current opportunities, please visit the <router-link to="/events">Events</router-link> Page.
                  <br><br>
                  Examples of online programming include...<br>
                  Virtual After School Sessions using PeaceJam’s Award-winning Curriculum<br>
                  Workshops on the Billion Acts of Peace Campaign (delving into issue areas and how to be activists)<br>
                  Virtual Summits featuring Nobel Laureates and Youth Activists and recurring Events
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import api from "../services/api";
  import Loading from "../components/Loading";
  import useSWRV from "swrv";
  import ProgramsCard from "../components/programs/ProgramsCard";

  export default {
    name: "Programs",
    setup() {
      let {
        data: programs_data,
        error: programs_error,
        isValidating: programs_isValidating
      } = useSWRV(() => `items/programs?status=published`, api.fetcher);

      return {
        programs_data,
        programs_error,
        programs_isValidating
      }
    },
    mounted() {
      window.scrollTo(0, 0);
    },
    components: {
      ProgramsCard,
      Loading
    },
    methods: {
      goProgramming() {
        this.$refs.programming.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      },
     
    }
  }
</script>

<style scoped lang="scss">
  .loading {
    height: 70vh;
  }
  .page-title-area-padding {
    background-image: url("../assets/img/GL.jpg");
    background-position: 50% 53%;

    > .container {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #00000059;
    }

    p {
      color: #fff;
      font-size: 17px;
    }
  }

  .bg-image {
    background-image: url("../assets/img/jobs-bg.jpg");
  }

  .page-text {
    padding: 30px;
    background: #f0effe;

    p {
      font-size: 18px;
    }
  }

  $bg: #f3f8fa;
  $white: #fff;
  $black: #7e73f2;

  @mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65,0,.076,1)) {
    transition: $property $duration $ease;
  }

  .events-btn-one {
    color: #fc4040;
  }

  .virtual-programming-area {
    p {
      font-size: 18px;
    }

    button {
      margin-top: 20px;
      position: relative;
      display: inline-block;
      cursor: pointer;
      outline: none;
      border: 0;
      vertical-align: middle;
      text-decoration: none;
      background: transparent;
      padding: 0;
      font-size: inherit;
      font-family: inherit;
      &.learn-more {
        width: 19rem;
        height: auto;
        .circle {
          @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
          position: relative;
          display: block;
          margin: 0;
          width: 3rem;
          height: 3rem;
          background: $black;
          border-radius: 1.625rem;
          .icon {
            @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            background: $white;
            &.arrow {
              @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
              left: 0.625rem;
              width: 1.125rem;
              height: 0.125rem;
              background: none;
              &::before {
                position: absolute;
                content: '';
                top: -0.25rem;
                right: 0.0625rem;
                width: 0.625rem;
                height: 0.625rem;
                border-top: 0.125rem solid #fff;
                border-right: 0.125rem solid #fff;
                transform: rotate(45deg);
              }
            }
          }
        }
        .button-text {
          @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 0.75rem 0;
          margin: 0 0 0 1.85rem;
          color: #252525;
          font-weight: 700;
          line-height: 1.6;
          text-align: center;
          text-transform: uppercase;
        }
      }
      &:hover {
        .circle {
          width: 100%;
          .icon {
            &.arrow {
              background: $white;
              transform: translate(1rem, 0);
            }
          }
        }
        .button-text {
          color: $white;
        }
      }
    }
  }
</style>
