<template>
  <div class="single-causes">
    <div class="image">
      <router-link :to="{ name: 'program', params: params({url: program.slug}) }">
        <DefaultImage :id="program.image" :width="'100%'" :height="'234px'"/>
      </router-link>

      <div class="icon" v-if="program.category">
        <span>{{program.category}}</span>
        
      </div>
      
    </div>

    <ProgramsType
      v-if="program.program_type"
      :id="program.program_type"
    />

    <div class="content program-card-content">
      <h3>{{program.name}}</h3>
    </div>
    
    <div class="description" v-if="program.tagline">
      <p class="d-inline" v-html="trimMyString(program.tagline, 100)"></p>
      <span class="d-inline ml-1 read-all"
            v-if="checkStringLength(program.tagline, 100)"
            :content="program.tagline" v-tippy
      >...</span>
    </div>

    <div class="causes-btn">
      <router-link :to="{ name: 'program', params: params({url: program.slug}) }" class="causes-btn-one">
        <i class="flaticon-study"></i>
        Learn More
      </router-link>
    </div>
  </div>
</template>

<script>
  import DefaultImage from "../DefaultImage";
  import ProgramsType from "./ProgramsType";

  export default {
    components: {ProgramsType, DefaultImage},
    props: ['program'],
    name: "ProgramsCard",
    methods: {
      params(newParams) {
        return {
          ...this.$route.params,
          ...newParams
        }
      },
      trimMyString(string, maxLength, start = 0) {
        if (string.length > maxLength) {
          let trimmedString = string.substr(start, maxLength)
          return (
              trimmedString.substr(
                  start,
                  Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
              )
          )
        }
        return string
      },
      checkStringLength(string, maxLength) {
        return string.length > maxLength;
      }
    },
  }
</script>

<style scoped>
  .read-all {
    border-radius: 3px;
    display: inline-block !important;
    background: #d5d5d5;
    height: 10px;
    line-height: 0;
    padding: 0 5px;
    font-size: 22px;
    color: #fff;
    cursor: pointer;
    outline: none;
  }

  .single-causes .content h3 {
    min-height: 82px;
    margin: 10px 0;
  }

  .single-causes:hover .program-card-content {
  margin-bottom: 55px;
}
  .program-card-content  {
    transition: 0.5s
  }
  .single-causes:hover .causes-list {
    margin-bottom: 0;
  }
  .single-causes .causes-list{
    padding:0;
  }
</style>
